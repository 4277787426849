/* eslint-disable no-unused-vars */

import React, { Suspense, useEffect, useState } from 'react';
import Header from './Header';
import LeftBar from './LeftBar';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatSlice,
  setDataLoginByToken,
  setCurrentActiveGroupData,
  setListMessagesMainChat,
  setListDataGroupPublic,
  setListGroupChatAnonymous,
  setScrollOnBottomChat,
  setListPublicElement,
  setListAnonymousElement,
  setIdJobSelectChat,
  setInfoEvaluateInterviewApplicant,
} from '../../../redux/reducerChat';

import * as TYPE from '../../../redux/actionType';

import { loginByToken, unReadMessageApplyUser, unReadMessageContactUser } from '../../../../services/ChatService';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../common/SpinLoading';
import { socketIO } from '../../../../services/socket';
import RightBar from './RightBar';
import MainChat from './MainChat';
import { toast } from '../../../../utils/notification';

const ChatComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const applyGroupIDfromURL = params.get('groupId');
  const jobIDfromURL = params.get('jobId');
  const {
    socketConnect,
    dataLoginByToken,
    jobIDinSelectChat,
    currentActiveGroup,
    listGroupChatAnonymous,
    listDataGroupPublic,
    messagesMainChat,
    scrollOnBottomChat,
    evaluateInterviewApplicant,
  } = useSelector(chatSlice);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const activeGroupChatId = currentActiveGroup?._id;
  const token = JSON.parse(localStorage.getItem('token'));
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Socket connected: ', socketConnect)

    if (!token) {
      return navigate('/login');
    }
    const loginByTokenRecruiter = async () => {

      if(socketConnect){
        const data = await loginByToken();

        dispatch(setDataLoginByToken(data));
        setLoading(false);
      }

    };

    loginByTokenRecruiter();
  }, [socketConnect]);

  // When navigate from search list to the Chat page
  useEffect(() => {
    if(!dataLoginByToken.applyGroups){
      return
    }
    if(!socketConnect){
      return
    }


    if (jobIDfromURL) {
      dispatch(setIdJobSelectChat(jobIDfromURL));
    }

    if (applyGroupIDfromURL) {
      const forcusApplyGroup = dataLoginByToken?.applyGroups?.find((item) => item?._id === applyGroupIDfromURL);
      if (forcusApplyGroup) {
        dispatch(setCurrentActiveGroupData(forcusApplyGroup));
      } else {
        // Toast
        // show meesage not exist group ID
        toast('error', 'チャットデータ無し')

      }
    }
  }, [applyGroupIDfromURL, jobIDfromURL, dataLoginByToken]);

  // When anonymous or applicantUser send to
  const handleReceiveMessage = (receivedMessage, listGroupsChat, setListGroupsChat, unReadMessage) => {
    // update list messages in Main Chat
    if (receivedMessage && receivedMessage?.to === activeGroupChatId) {
      let afterChat;
      if (receivedMessage.fromHR) {
        afterChat = [
          ...messagesMainChat,
          {
            ...receivedMessage,
            from: {
              _id: receivedMessage.fromHR._id,
              lastName: receivedMessage.fromHR.lastName,
              firstName: receivedMessage.fromHR.firstName,
            },
          },
        ];
      } else {
        afterChat = [...messagesMainChat, receivedMessage];
      }

      dispatch(setListMessagesMainChat(afterChat));
      dispatch(setScrollOnBottomChat(!scrollOnBottomChat));
      // set data interview
      if (receivedMessage?.type === 'interview') {
        dispatch(
          setInfoEvaluateInterviewApplicant({
            ...evaluateInterviewApplicant,
            progress: [
              {
                interviewEnd: JSON.parse(receivedMessage?.content)?.interviewEnd,
                interviewStart: JSON.parse(receivedMessage?.content)?.interviewStart,
                result: JSON.parse(receivedMessage?.content)?.result ?? '',
                step: JSON.parse(receivedMessage?.content)?.step ?? '',
                _id: JSON.parse(receivedMessage?.content)?.progressId ?? '',
              },
            ],
          })
        );
      }
    }
    // updateLastMessageLeftBarList
    if (receivedMessage) {
      let receivedContactGroup = listGroupsChat.find((group) => group._id === receivedMessage?.to);
      let newListGroupChat;
      const clonedListGroupChatLeftBar = [...listGroupsChat];

      if (receivedMessage?.to !== activeGroupChatId) {
        newListGroupChat = { ...receivedContactGroup, lastMessage: receivedMessage, unReadMessage: 1 };
        // set data interview
        if (receivedMessage?.type === 'interview') {
          newListGroupChat = {
            ...newListGroupChat,
            application: {
              ...newListGroupChat?.application,
              progress: [
                {
                  interviewEnd: JSON.parse(receivedMessage?.content)?.interviewEnd,
                  interviewStart: JSON.parse(receivedMessage?.content)?.interviewStart,
                  result: JSON.parse(receivedMessage?.content)?.result ?? '',
                  step: JSON.parse(receivedMessage?.content)?.step ?? '',
                  _id: JSON.parse(receivedMessage?.content)?.progressId ?? '',
                },
              ],
            },
          };
        }
      } else {
        unReadMessage(activeGroupChatId, receivedMessage?._id);
        newListGroupChat = { ...receivedContactGroup, lastMessage: receivedMessage, unReadMessage: 0 };
      }

      const indexItem = listGroupsChat.findIndex((group) => group._id === receivedMessage?.to);

      clonedListGroupChatLeftBar.splice(indexItem, 1);
      clonedListGroupChatLeftBar.unshift(newListGroupChat);
      dispatch(setListGroupsChat(clonedListGroupChatLeftBar));
    }
  };

  // Event
  useEffect(() => {
    socketIO.on('applyMessage', async (receivedMessageApply) => {
      if (receivedMessageApply?.jobId === jobIDinSelectChat || jobIDinSelectChat === '') {
        handleReceiveMessage(receivedMessageApply, listDataGroupPublic, setListDataGroupPublic, unReadMessageApplyUser);
      }
    });

    // update main chat
    socketIO.on('sendProfile', async (receivedMessageApply) => {
      if (receivedMessageApply?.jobId === jobIDinSelectChat || jobIDinSelectChat === '') {
        handleReceiveMessage(receivedMessageApply, listDataGroupPublic, setListDataGroupPublic, unReadMessageApplyUser);
      }
    });

    socketIO.on('contactMessage', async (receivedMessageContact) => {
      if (receivedMessageContact?.jobId === jobIDinSelectChat || jobIDinSelectChat === '') {
        handleReceiveMessage(
          receivedMessageContact,
          listGroupChatAnonymous,
          setListGroupChatAnonymous,
          unReadMessageContactUser
        );
      }
    });
    return () => {
      socketIO.off('applyMessage');
      socketIO.off('sendProfile');
      socketIO.off('contactMessage');
    };
  });

  const handleListenDeletedMessage = (deletedMessage, setListGroupsChangeElement) => {
    // update messages main chat
    const afterDeletedMessages = messagesMainChat.map((m) => {
      if (m._id === deletedMessage.messageId) {
        return {
          ...m,
          deleted: true,
        };
      } else {
        return m;
      }
    });
    dispatch(setListMessagesMainChat(afterDeletedMessages));

    const deletedEventGroupId = deletedMessage.applyGroupId
      ? deletedMessage.applyGroupId
      : deletedMessage.contactGroupId;

    // updateLastMessageLeftBarList
    const updateWant = {
      type: TYPE.LISTEN_DELETE_MESSAGE,
      groupId: deletedEventGroupId,
      messageId: deletedMessage.messageId,
      updating: { deleted: true },
    };

    dispatch(setListGroupsChangeElement(updateWant));
  };
  //Handle event Delete Message
  useEffect(() => {
    socketIO.on('deleteApplyMessage', async (deletedMessage) => {
      if (deletedMessage) {
        handleListenDeletedMessage(deletedMessage, setListPublicElement);
      }
    });
    socketIO.on('deleteContactMessage', async (deletedMessage) => {
      if (deletedMessage) {
        handleListenDeletedMessage(deletedMessage, setListAnonymousElement);
      }
    });
    return () => {
      socketIO.off('deleteApplyMessage');
      socketIO.off('deleteContactMessage');
    };
  });

  useEffect(() => {
    const newItemNewMessPublicCounted = listDataGroupPublic?.filter((item) => item.unReadMessage > 0);
    const newItemNewMessAnonymousCounted = listGroupChatAnonymous?.filter((item) => item.unReadMessage > 0);
    const totalNewMessCounted = newItemNewMessPublicCounted?.length + newItemNewMessAnonymousCounted?.length;
    if (totalNewMessCounted > 0) {
      document.title = `(${totalNewMessCounted})CAERU採用管理`;
    }
  }, [listGroupChatAnonymous, listDataGroupPublic]);

  return (
    <>
      <Suspense>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Header />
            <div className='layout__main jus'>
              <div className={`layout__chatContainer ${activeGroupChatId ? '' : '-unselected'}`}>
                <LeftBar loadingLogin={loading} />
                {activeGroupChatId && Object?.keys(currentActiveGroup).length > 0 && (
                  <>
                    <MainChat />
                    <RightBar groupId={activeGroupChatId} />
                  </>
                )}
                {!activeGroupChatId ||
                  (Object?.keys(currentActiveGroup).length === 0 && (
                    <div className='layout__unselectedContent'>
                      <p className='u-font-lg u-text-light'>
                        応募者一覧またはコンタクト一覧から会話を選択してください。
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </Suspense>
    </>
  );
};
export default ChatComponent;
